// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#footerSmall {
   display: none;
}

footer {
   position: absolute;
   /* bottom: 0; */
   left: 0;
   right: 0;
}

.footer-copyright {
   margin-top: 120px;
   padding: 30px 60px;
   background-color: #101010;
   /* border-radius: 14px 14px 0px 0px; */
   /* border-top: 3px solid #F9D423; */
   box-shadow: 0px -4px 40px rgba(0, 0, 0, 0.2);
   color: white;
   font-family: 'Montserrat', sans-serif;
   font-size: 16px;
   font-weight: 500;
   text-align: center;
}

.copy-brand {
   color: #ff6c0a;
}

@media screen and (max-width: 438px) {
   #footerLarge {
      display: none;
   }

   #footerSmall {
      display: block;
   }
}`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAAA;GACG,aAAa;AAChB;;AAEA;GACG,kBAAkB;GAClB,eAAe;GACf,OAAO;GACP,QAAQ;AACX;;AAEA;GACG,iBAAiB;GACjB,kBAAkB;GAClB,yBAAyB;GACzB,sCAAsC;GACtC,mCAAmC;GACnC,4CAA4C;GAC5C,YAAY;GACZ,qCAAqC;GACrC,eAAe;GACf,gBAAgB;GAChB,kBAAkB;AACrB;;AAEA;GACG,cAAc;AACjB;;AAEA;GACG;MACG,aAAa;GAChB;;GAEA;MACG,cAAc;GACjB;AACH","sourcesContent":["#footerSmall {\r\n   display: none;\r\n}\r\n\r\nfooter {\r\n   position: absolute;\r\n   /* bottom: 0; */\r\n   left: 0;\r\n   right: 0;\r\n}\r\n\r\n.footer-copyright {\r\n   margin-top: 120px;\r\n   padding: 30px 60px;\r\n   background-color: #101010;\r\n   /* border-radius: 14px 14px 0px 0px; */\r\n   /* border-top: 3px solid #F9D423; */\r\n   box-shadow: 0px -4px 40px rgba(0, 0, 0, 0.2);\r\n   color: white;\r\n   font-family: 'Montserrat', sans-serif;\r\n   font-size: 16px;\r\n   font-weight: 500;\r\n   text-align: center;\r\n}\r\n\r\n.copy-brand {\r\n   color: #ff6c0a;\r\n}\r\n\r\n@media screen and (max-width: 438px) {\r\n   #footerLarge {\r\n      display: none;\r\n   }\r\n\r\n   #footerSmall {\r\n      display: block;\r\n   }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
